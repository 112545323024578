// .landing-page__banner {
//     padding-top: $spacing-05;
//     /* padding-bottom: $spacing-07 * 4; */
//   }

.salmon-color {
    background-color: #FA8072;
}

@media (max-width:9000px){//resolution 1920
    .margin-logo {
        //margin-top: 170px !important;
        //margin-left: 290px !important;
        padding-top: 200px;
        padding-left: 290px;
    }
    .margin-logo-btn {
        //margin-right: 50px !important;
        padding-right: 245px;
        padding-left: 215px;
        display: grid!important;
        //padding-left: 395px;
    }
    
}

@media (max-width:1600px){
    .margin-logo {
        //margin-top: 170px !important;
        //margin-left: 290px !important;
        padding-top: 70px;
        padding-left: 20px;
    }
    .margin-logo-btn {
        //margin-right: 50px !important;
        //padding-right: 245px;
        padding-left: 125px;
        padding-bottom: 20px;
        display: grid!important;
        //padding-left: 395px;
    }
}

.margin-primaryImg {
    margin-left: 190px !important;
}